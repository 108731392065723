import './ProgressCircle.css'

const ProgressCircle = ({
	size=20,
	color='white',
	backgroundColor='var(--background)',
}) => (
	<div
		className="wrapper"
		style={{
			width: size,
			height: size,
		}}
	>
		<div
			className="block"
			style={{
				width: size,
				height: size,
				clip: `rect(0px, ${size}px, ${size}px, ${size/2}px)`,
				backgroundColor,
			}}
		/>
		<div
			className="left"
			style={{
				width: size - 2,
				height: size - 2,
				border: `1px solid ${backgroundColor}`,
				backgroundColor: color,
				borderRadius: size/2,
				clip: `rect(0px, ${size/2}px, ${size}px, 0px)`,
			}}
		/>
		<div
			className="right"
			style={{
				width: size - 2,
				height: size - 2,
				border: `1px solid ${backgroundColor}`,
				backgroundColor: color,
				borderRadius: size/2,
				clip: `rect(0px, ${size}px, ${size}px, ${size/2}px)`,
			}}
		/>
	</div>
)

export default ProgressCircle
