import { useEffect, useRef, useState } from "react"
import ProgressCircle from "../ProgressCircle/ProgressCircle"
import './Scrubber.css'

const Scrubber = ({
	values = [],
	format = value => value,
	initial = 0,
	delay = 300,
	autoplay = true,
	loop = true,
	// loopDelay = 1000,
	changeValue,
}) => {

	const [state, setState] = useState({
		running: autoplay,
	})

	const interval = useRef(null)

	const [value, setValue] = useState(initial)

	useEffect(() => {
		changeValue(values[value])
	}, [value])

	const clear = () => {
		if (interval.current !== null) clearInterval(interval.current)
	}

	useEffect(() => {
		clear()
		if (autoplay) start()
		else stop()
	}, [])

	const start = () => {
		clear()
		interval.current = setInterval(step, delay)
		if (value === values.length - 1) {
			setValue(0)
		}
		setState({
			...state,
			running: true,
		})
	}

	const stop = () => {
		clear()
		setState({
			...state,
			running: false,
		})
	}

	const step = () => {
		setValue(value => {
			if (value === values.length - 1) {
				if (loop) {
					// loop delay
					// clear()
					// setTimeout(() => interval.current = setInterval(step, delay), loopDelay)
				} else {
					stop()
					return value
				}
			}
			if (!loop && value === values.length - 2) stop()
			return (value + 1) % values.length
		})
	}

	let button = (
		<i
			className='material-icons opacity-on-hover'
			style={{ marginRight: '0.4em', color: 'white', cursor: 'pointer', userSelect: 'none' }}
			onClick={() => (state.running ? stop() : start())}
		>{state.running ? 'pause' : 'play_arrow'}</i>
	)

	let input = (
		<input
			className='slider'
			name='i'
			type='range'
			min={0}
			max={values.length - 1}
			value={value}
			step={1}
			onChange={event => {
				if(state.running) stop()
				setValue(parseInt(event.target.value))
			}}
		/>
	)
	let output = (
		<output name='o' style={{ marginLeft: '0.4em', width: 150 }}>
			{format(values[value])}
		</output>
	)

	values = Array.from(values)
	const form = (
		<form style={{
			font: '12px var(--sans-serif)',
			fontVariantNumeric: 'tabular-nums',
			display: 'flex',
			height: 33,
			alignItems: 'center',
			margin: '0 10px'
		}}>
			{button}
			<label style={{
				display: 'flex',
				alignItems: 'center',
			}}>
				{input}
				{output}
				<div style={{ width: 14 }}>
					{state.running &&
						<ProgressCircle size={14} />
					}
				</div>
			</label>
		</form>
	)

	return form
}

export default Scrubber
