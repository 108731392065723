import { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import d3cloud from 'd3-cloud'
import Scrubber from './Scrubber/Scrubber'
import Tweet from './Tweet/Tweet'
import dateFormat from 'dateformat'

const headerHeight = 50

const WordCloud = ({ chartHeight }) => {

	const wordcloudref = useRef(null)

	const [month, changeMonth] = useState('2019-11-01')

	const [data, setData] = useState(null)

	const [trumpTweets, setTrumpTweets] = useState([])
	const [bidenTweets, setBidenTweets] = useState([])

	const [filterWord, setFilterWord] = useState(null)

	// get data
	useEffect(() => {
		d3.csv(
			'/data/words_from.csv',
			d => {
				let d1 = {
					...d,
					count_trump: parseInt(d.count_trump),
					count_biden: parseInt(d.count_biden),
					freq_trump: parseFloat(d.freq_trump),
					freq_biden: parseFloat(d.freq_biden),
				}
				return {
					...d1,
					freq: d1.freq_trump + d1.freq_biden,
					metric: d1.freq_trump - d1.freq_biden,
				}
			}
		).then(d => setData(d))
		d3.csv(
			'/data/trump_tweets.csv',
			d => ({
				...d,
				monthSubstr: d.date.substr(0, 7),
			})
		).then(tweets => setTrumpTweets(tweets))
		d3.csv(
			'/data/biden_tweets.csv',
			d => ({
				...d,
				monthSubstr: d.date.substr(0, 7),
			})
		).then(tweets => setBidenTweets(tweets))
	}, [])

	// reset scroll
	useEffect(() => {
		d3.selectAll('#wordcloud-biden-tweets').node().scrollTo(0, 0)
		d3.selectAll('#wordcloud-trump-tweets').node().scrollTo(0, 0)
	}, [month, filterWord])

	// make word cloud
	useEffect(() => {

		if (!data) return

		const quantile_order = [
			'very_biden', 'slightly_biden', 'middle', 'slightly_trump', 'very_trump'
		]
		const quantiles = {
			very_biden: {
				lower_percentile: 0.0,
				upper_percentile: 0.2,
				color: 'var(--blue)',
			},
			slightly_biden: {
				lower_percentile: 0.2,
				upper_percentile: 0.4,
				color: 'var(--light-blue)',
			},
			middle: {
				lower_percentile: 0.4,
				upper_percentile: 0.6,
				color: 'white',
			},
			slightly_trump: {
				lower_percentile: 0.6,
				upper_percentile: 0.8,
				color: 'var(--light-red)',
			},
			very_trump: {
				lower_percentile: 0.8,
				upper_percentile: 1.0,
				color: 'var(--red)',
			},
		}

		let filteredData = data.filter(d => d.month === month)
		filteredData.sort((a, b) => b.metric - a.metric)

		const wordcloudheight = chartHeight - headerHeight //wordcloudref.current.getBoundingClientRect().height
		const wordcloudwidth = wordcloudref.current.getBoundingClientRect().width
		var margin = { top: 0, right: 0, bottom: 0, left: 0 },
			width = wordcloudwidth - margin.left - margin.right,
			height = (wordcloudheight / 5) - margin.top - margin.bottom

		const wordcloud = d3.select(wordcloudref.current)
		wordcloud.selectAll('*').remove()

		for (let quantile of quantile_order) {
			const q = quantiles[quantile]
			q.lower_value = d3.quantile(filteredData, q.lower_percentile, d => d.metric)
			q.upper_value = d3.quantile(filteredData, q.upper_percentile, d => d.metric)
			// filter data
			q.data = filteredData.filter(d => q.lower_value <= d.metric && d.metric < q.upper_value).sort((a, b) => b.freq - a.freq).slice(0, 100)
			// create size scale
			q.size = d3.scaleLog().domain(d3.extent(q.data, d => d.freq)).range([8, height * 0.45])
			// add size to word data
			q.data = q.data.map(d => ({ ...d, size: q.size(d.freq), quantile, }))

			q.svg = d3.select(wordcloudref.current)
				.append('div')
					.style('flex-grow', 1)
					.style('height', wordcloudheight / 5 + 'px')
					.style('overflow', 'hidden')
				.append("svg")
					.attr("width", width + margin.left + margin.right)
					.attr("height", height + margin.top + margin.bottom)
					.append("g")
					.attr("transform", "translate(" + margin.left + "," + margin.top + ")")

			q.layout = d3cloud()
				.size([width, height])
				.words(q.data.map(d => ({ text: d.word, size: d.size, ...d })))
				.padding(3)        //space between words
				.rotate(0)
				.fontSize(d => d.size)      // font size of words
				// .font('Impact')     // TODO: change font?
				.spiral('rectangular')
				.on("end", (words) => draw(words, {...q, name: quantile}))
			q.layout.start()
		}

		d3.select('#tooltip').remove()

		const getTooltipText = d => {
			const trumpNum = Math.round(10000 * d.freq_trump) / 10
			const trumpText = `
				<div style='line-height: 1.1; margin: 10px 0;'><span style='font-weight: bold; color: var(--light-red); font-size: 20px;'>${trumpNum}</span> time${trumpNum !== 1 ? 's' : ''} per 1,000 Trump tweets</div>
			`
			const bidenNum = Math.round(10000 * d.freq_biden) / 10
			const bidenText = `
				<div style='line-height: 1.1; margin: 10px 0;'><span style='font-weight: bold; color: var(--light-blue); font-size: 20px;'>${bidenNum}</span> time${bidenNum !== 1 ? 's' : ''} per 1,000 Biden tweets</div>
			`
			let moreText
			if (Math.min(d.count_trump, d.count_biden) === 0) {
				moreText = `Used exclusively in <span style='font-weight: bold; color: var(--light-${d.metric > 0 ? 'red' : 'blue'});'>${d.metric > 0 ? 'Trump' : 'Biden'}</span> tweets this month`
			} else {
				const moreNum = (d.metric > 0 ? 1 : -1) * Math.round(10000 * d.metric) / 10
				moreText = `${d.metric > 0 ? 'Trump' : 'Biden'} used this word <span style='font-weight: bold; color: var(--light-${d.metric > 0 ? 'red' : 'blue'}); font-size: 20px;'>${moreNum}</span> more time${moreNum !== 1 ? 's' : ''} than ${d.metric > 0 ? 'Biden' : 'Trump'} per 1,000 tweets this month`
			}
			return `
				<div style='font-weight: bold; font-size: 23px; text-align: center;'>${d.word}</div>
				<div style='line-height: 1.2; margin: 10px 0'>${moreText}</div>
				${(d.metric > 0) ? trumpText + bidenText : bidenText + trumpText }
				<div style='line-height: 1.1; margin-bottom: 10px; margin-top: 5px;'>Used <span style='font-weight: bold; color: white;'>${d.count_trump + d.count_biden}</span> time${(d.count_trump + d.count_biden) !== 1 ? 's' : ''} total by both candidates</div>
				<div style='text-align: center; opacity: 0.7; font-size: 14px;'>click to see tweets using <b>${d.word}</b></div>
			`
		}
		const tooltip = d3.select('#app').append('div').attr('id', 'tooltip')
			.style('background-color', '#333')
			.style('color', 'white')
			.style('position', 'absolute')
			.style('padding', '10px 20px')
			.style('border-radius', '10px')
			.style('transition-duration', '0.2s')
			.style('font-family', "'Amiri', serif")
			.style('display', 'none')
			.style('top', 100)
			.style('left', 100)
			.style('max-width', '250px')
			.style('box-shadow', '5px -3px 12px 0px #000000')
			.style('z-index', 2000)

		function draw(words, q) {
			const svg = q.svg
			const layout = q.layout
			svg.append("g")
				.attr('class', q.name)
				.attr("transform", "translate(" + layout.size()[0] / 2 + "," + layout.size()[1] / 2 + ")")
				.selectAll("text")
				.data(words)
				.enter().append("text").attr('class', 'word-in-cloud opacity-on-hover')
				.style('opacity', d => (d.word === filterWord || !filterWord) ? undefined : 0.5)
				.style("font-size", d => d.size)
				.style("fill", q.color)
				.style('cursor', 'pointer')
				.style('user-select', 'none')
				.attr("text-anchor", "middle")
				// .style("font-family", "Impact")
				.attr("transform", d => "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")")
				.text(d => d.text)
				.on('mouseover', (event, d) => {
					tooltip
						.style('display', undefined)
						.style('left', event.clientX + 'px')
						.style('bottom', (window.innerHeight - event.clientY) + 'px')
						.html(getTooltipText(d))
				})
				.on('mouseout', () => {
					tooltip
						.style('display', 'none')
				})
				.on('click', (event, d) => setFilterWord(fW => {
					return d.word === fW ? null : d.word
				}))

		}
	}, [month, data])

	useEffect(() => {
		d3.selectAll('.word-in-cloud').style('opacity', d => (d.word === filterWord || !filterWord) ? undefined : 0.5)
	}, [filterWord])

	let filteredTrumpTweets = []
	let filteredBidenTweets = []
	if (trumpTweets.length > 0 && bidenTweets.length > 0) {
		const monthSubstr = month.substr(0, 7)
		filteredTrumpTweets = trumpTweets.filter(d => d.monthSubstr === monthSubstr)
		filteredBidenTweets = bidenTweets.filter(d => d.monthSubstr === monthSubstr)
		if (filterWord) {
			filteredTrumpTweets = filteredTrumpTweets.filter(d => d.words.includes(filterWord))
			filteredBidenTweets = filteredBidenTweets.filter(d => d.words.includes(filterWord))
		}
		// console.log(filteredTrumpTweets[0])
		// console.log(filteredBidenTweets[0])
	}

	return (
		<div className='chart' style={{
			display: 'flex',
			height: chartHeight,
			width: 1300
		}}>
			<div style={{ width: 300 }}>
				<div style={{
					height: headerHeight,
					display: 'flex',
					alignItems: 'flex-end',
					justifyContent: 'center',
					fontWeight: 'bold',
					fontSize: 27,
					paddingLeft: 10,
					color: 'var(--light-blue)',
				}}>Joe Biden</div>
				<div id='wordcloud-biden-tweets' style={{
					overflowY: 'scroll',
					height: `calc(${chartHeight}px - ${headerHeight}px)`,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}>
					{filteredBidenTweets.map(tweet => (
						<Tweet
							key={tweet[""]}
							width={250}
							color='blue'
							person='biden'
							text={tweet.text}
							fontSize={13}
							date={new Date(tweet.date)}
							likes={tweet.likes}
							retweets={tweet.retweets}
							href={tweet.url}
						/>
					))}
				</div>
			</div>
			<div style={{ width: 700, display: 'flex', flexDirection: 'column', }}>
				<div style={{ height: headerHeight, flexShrink: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 40 }}>
					<Scrubber
						values={['2019-11-01', '2019-12-01', '2020-01-01', '2020-02-01', '2020-03-01', '2020-04-01', '2020-05-01', '2020-06-01', '2020-07-01', '2020-08-01', '2020-09-01', '2020-10-01',]}
						initial={0}
						delay={10000}
						format={date => dateFormat(date.substr(0, 7) + '-15', 'mmmm yyyy')}
						changeValue={m => {
							changeMonth(m)
							setFilterWord(null)
						}}
						autoplay={false}
					/>
					{!filterWord &&
						<div style={{ fontSize: 14, userSelect: 'none' }}>Data is monthly, advances every 10s</div>
					}
					{filterWord &&
						<div
							className='opacity-on-hover'
							style={{ fontSize: 20, userSelect: 'none', cursor: 'pointer', }}
							onClick={() => setFilterWord(null)}
						>
							clear filter on <b>{filterWord}</b>
						</div>
					}
				</div>
				<div
					id='wordcloud'
					style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}
					ref={wordcloudref}
				/>
			</div>
			<div style={{ width: 300 }}>
				<div style={{
					height: headerHeight,
					display: 'flex',
					alignItems: 'flex-end',
					justifyContent: 'center',
					fontWeight: 'bold',
					fontSize: 27,
					paddingRight: 10,
					color: 'var(--light-red)',
				}}>Donald Trump</div>
				<div id='wordcloud-trump-tweets' style={{
					overflowY: 'scroll',
					height: `calc(${chartHeight}px - ${headerHeight}px)`,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}>
					{filteredTrumpTweets.map(tweet => (
						<Tweet
							key={tweet[""]}
							width={250}
							color='red'
							person='trump'
							text={tweet.text}
							fontSize={13}
							date={new Date(tweet.date)}
							likes={tweet.likes}
							retweets={tweet.retweets}
							href={`https://twitter.com/realDonaldTrump/status/${tweet.id}`}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export default WordCloud
