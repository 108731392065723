const getTweetNumText = numAsString => {
	const num = parseInt(numAsString)
	if (num < 1000) {
		return numAsString
	} else if (num < 1000000) {
		return (Math.round((parseInt(numAsString) / 1000) * 10) / 10) + 'K'
	} else {
		return (Math.round((parseInt(numAsString) / 1000000) * 10) / 10) + 'M'
	}
}

export { getTweetNumText as getTweetNumText }