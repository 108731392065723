import { useEffect, useRef, useState } from "react"
import * as d3 from 'd3'
import dateFormat from 'dateformat'

const axesOpacity = 0.9

const WordLines = ({ chartHeight }) => {

	const ref = useRef(null)

	const [month, setMonth] = useState(null)
	const isMonth = () => month !== null

	const [selected, setSelected] = useState([])

	const [data, setData] = useState(null)

	const [search, setSearch] = useState('')

	const dateStrings = ['2019-11-01', '2019-12-01', '2020-01-01', '2020-02-01', '2020-03-01', '2020-04-01', '2020-05-01', '2020-06-01', '2020-07-01', '2020-08-01', '2020-09-01', '2020-10-01',]
	const dates = dateStrings.map(d => new Date(d.substr(0, 7) + '-01'))
	const getCurDateString = () => dateStrings[month].substr(0, 7) + '-15'

	const headerHeight = 80
	const plotHeight = chartHeight - headerHeight

	useEffect(() => {
		d3.csv(
			'/data/words_from_monthly.csv',
			d => {
				const counts = []
				for (let date of dateStrings) {
					counts.push(parseInt(d[date]))
				}
				if (d.word.includes('__')) return {
					trump: d.word === '__trump_count__',
					biden: d.word === '__biden_count__',
					counts,
				}
				return {
					word: d.word,
					total: parseInt(d.total),
					total_trump: parseInt(d.total_trump),
					total_biden: parseInt(d.total_biden),
					counts,
				}
			}
		).then(rows => {
			let counts = rows.slice(0, 2)
			let trump_counts = []
			let biden_counts = []
			for (let f of counts) {
				if (f.trump) trump_counts = f.counts
				if (f.biden) biden_counts = f.counts
			}
			const datum = rows.slice(2).map(r => {
				const percentages = []
				for (let [i, c] of r.counts.entries()) {
					percentages.push(1000 * c / (trump_counts[i] + biden_counts[i]))
				}
				return {
					...r,
					percentages,
				}
			})
			setData(datum)
		})
	}, [])

	// scroll to top
	useEffect(() => {
		d3.select('#word-list').node().scrollTo(0, 0)
	}, [month, selected])

	useEffect(() => {

		if (!data) return

		// filter
		let filteredData = data.slice()

		const defaultSort = (a, b) => {
			if (isMonth()) {
				return b.percentages[month] - a.percentages[month]
				// return b.counts[month] - a.counts[month]
			} else {
				return b.total - a.total
			}
		}
		filteredData.sort(defaultSort)
		filteredData = filteredData.slice(0, 100)
		filteredData = filteredData.map((d, i) => ({
			...d,
			selected: selected.includes(d.word),
			matchesSearch: search.length > 0 ? d.word.includes(search) : true,
			rank: i,
		}))

		const top100words = filteredData.map(d => d.word)
		const newSelected = selected.filter(s => top100words.includes(s))
		if (newSelected.length < selected.length) {
			setSelected(newSelected)
			return
		}

		filteredData.sort((a, b) => {
			if (a.selected === b.selected) {
				return defaultSort(a, b)
			}
			if (a.selected) return -1
			return 1
		})

		// word list
		const wordlist = d3.select('#word-list')

		wordlist.selectAll('.word-list-word').data(filteredData.filter(d => d.matchesSearch)).join('div')
				.attr('class', 'word-list-word opacity-on-hover')
				.style('padding', '10px')
				.style('margin', '5px 15px 10px 15px')
				.style('border-radius', '3px')
				.style('border', d => d.total_trump > d.total_biden ? '1px solid var(--light-red)' : '1px solid var(--light-blue)')
				.style('user-select', 'none')
				.style('cursor', 'pointer')
				.style('overflow', 'hidden')
				.on('click', (_, d) => {
					const index = selected.indexOf(d.word)
					if (index === -1) {
						setSelected([...selected, d.word])
					} else {
						const newSelected = [...selected]
						newSelected.splice(index, 1)
						setSelected(newSelected)
					}
				})
				.style('display', 'flex')
				.style('flex-direction', 'row')
				.html(d => {
					return `
						<div style='flex-grow: 1'>
							${(() => {
								if (search.length > 0) {
									const index = d.word.indexOf(search)
									return `<span style='opacity: 0.9'>${d.word.substr(0, index)}</span><b>${search}</b><span style='opacity: 0.9'>${d.word.substr(index + search.length)}</span>`
								}
								return d.word
							})()}
						</div>
						<div>${isMonth() ? d.counts[month] : d.total}</div>
					`
				})
			.filter(d => d.selected)
				.style('flex-direction', 'column')
				.style('background-color', 'transparent')
				.style('color', 'white')
				.style('border', 'none')
				.style('padding', 0)
				.html(d => {
					return `
						<div style='display: flex; background-color: #faf2ff; color: black; padding: 7px 10px; border-radius: 3px 3px 0 0; font-weight: bold'>
							<div style='flex-grow: 1'>${d.rank + 1} &nbsp;${d.word}</div>
							<div>${isMonth() ? d.counts[month] : d.total}</div>
						</div>
						<div style='padding: 10px; font-weight: normal; font-size: 15px; border-radius: 3px; border: ${d.total_trump > d.total_biden ? '1px solid var(--light-red)' : '1px solid var(--light-blue)'}; border-top: none;'>
							Overall mentions
							<div>
								${(() => {
									const trumpText = `
										<span style='font-weight: bold; color: var(--light-red)'>${d.total_trump}</span> Trump
									`
									const bidenText = `
										<span style='font-weight: bold; color: var(--light-blue)'>${d.total_biden}</span> Biden
									`
									if (d.total_trump > d.total_biden) {
										return trumpText + '&nbsp;' + bidenText
									}
									return bidenText + '&nbsp;' + trumpText
								})()}
							</div>
							${isMonth() ? `
								<div style='margin-top: 8px; line-height: 1'>This month, a combined</div>
								<span style='font-weight: bold;'>${Math.round(d.percentages[month])}</span> per 1,000 tweets</span>
							` : '' }
						</div>
					`
				})

		// plot
		const sizes = {
			height: plotHeight,
			width: ref.current.getBoundingClientRect().width,
			margin: {
				top: 10,
				right: selected.length === 0 ? 40 : 80,
				left: 65,
				bottom: 60,
			}
		}
		const div = d3.select('#word-lines')
		div.selectAll('*').remove()
		const plot = div.append('svg')
			.attr('width', sizes.width)
			.attr('height', sizes.height)
			.style('cursor', 'pointer')

		// axes
		const x = d3.scaleTime().domain([new Date('11-01-2019'), new Date('10-01-2020')]).range([sizes.margin.left, sizes.width - sizes.margin.right])

		const y = d3.scaleLinear().domain([0, d3.max(filteredData, d1 => d3.max(d1.percentages))]).range([sizes.height - sizes.margin.bottom, sizes.margin.top])

		// text next to selected lines
		plot.append('g')
			.selectAll('text')
			.data(filteredData.filter(d => selected.includes(d.word)))
			.join('text')
				.text(d => d.word)
				.attr('transform', d => `translate(${sizes.width - sizes.margin.right + 5}, ${y(d.percentages[d.percentages.length - 1])})`)
				.attr('alignment-baseline', 'middle')
				.attr('fill', 'white')
				.style('opacity', 0.8)

		// vertical line on selected month
		if (isMonth()) {
			plot.append('line').attr('id', 'selectline')
				.attr('x1', x(dates[month])).attr('y1', sizes.height - sizes.margin.bottom)
				.attr('x2', x(dates[month])).attr('y2', sizes.margin.top)
				.style('stroke-width', 1)
				.style('stroke', '#fff')
				.style('opacity', month === 0 ? 1 : 0.8)
				.style('fill', 'none')
		}

		// axes
		const xAxis = plot.append('g').attr('id', 'xaxis')
			.attr('transform', `translate(0,${sizes.height - sizes.margin.bottom})`)
			.style('user-select', 'none')
			.style('cursor', 'pointer')
			.style('opacity', axesOpacity)
			.call(d3.axisBottom(x).tickSizeOuter(0).tickFormat(d3.timeFormat("%b '%y")))

		xAxis.selectAll('text').attr('id', (_, i) => 'xaxis-tick-' + i)
			.attr('class', 'xaxis-tick')
			.style('font-weight', (_, i) => (month === i ? 'bold' : 'normal'))
			.style('font-size', (_, i) => (month === i ? 13 : 'inherit'))
			.style('opacity', 1)

		const xAxisLabel = plot.append('text')
			.attr('transform', `translate(${sizes.width / 2},${sizes.height - 15})`)
			.style('text-anchor', 'middle')
			.style('fill', 'white')
			.style('opacity', axesOpacity)
			.style('user-select', 'none')
			.style('font-size', 18)
			.text('Month')

		const yAxis = plot.append('g')
			.attr('transform', `translate(${sizes.margin.left},0)`)
			.style('user-select', 'none')
			.style('opacity', month === 0 ? 0.4 : axesOpacity)
			.call(d3.axisLeft(y))

		const yAxisLabel = plot.append('text')
			.attr('transform', 'rotate(-90)')
			.attr('x', -(sizes.height / 2))
			.attr('dy', 26)
			.style('fill', 'white')
			.style('text-anchor', 'middle')
			.style('user-select', 'none')
			.style('opacity', axesOpacity)
			.style('font-size', 18)
			.text('Number of mentions per 1,000 tweets')

		plot.append('line').attr('id', 'hover-line').style('opacity', 0)

		const findClosestMonthIndex = event => {
			const nodes = d3.selectAll('#xaxis .tick > line').nodes()
			const min = d3.minIndex(
				nodes,
				line => {
					const bcr = line.getBoundingClientRect()
					const x = bcr.x + (bcr.width / 2)
					return Math.abs(x - event.clientX)
				}
			)
			return min
		}

		plot.on('mousemove', event => {
			const min = findClosestMonthIndex(event)
			plot.select('#hover-line').style('opacity', 0.2)
				.attr('x1', x(dates[min])).attr('y1', sizes.height - sizes.margin.bottom)
				.attr('x2', x(dates[min])).attr('y2', sizes.margin.top)
				.style('stroke-width', 1)
				.style('stroke', 'white')
				.style('fill', 'none')
		}).on('mouseout', () => {
			plot.select('#hover-line')
				.style('opacity', 0)
		}).on('click', event => {
			setMonth(m => {
				const newMonth = findClosestMonthIndex(event)
				if (newMonth === m) return null
				else return newMonth
			})
		})

		// lines
		const line = d3.line()
			.defined(d => !isNaN(d))
			.x((_, i) => x(dates[i]))
			.y(d => y(d))

		const isHighlight = d => {
			if (d.selected) return true
			if (search.length === 0) return false
			return (d.matchesSearch && selected.length === 0)
		}

		const existsHighlight = (selected.length > 0 || search.length > 0)

		const path = plot.append('g')
				.attr('fill', 'none')
				.attr('stroke-width', 1.5)
				.attr('stroke-linejoin', 'round')
				.attr('stroke-linecap', 'round')
			.selectAll('path')
			.data(filteredData)
			.join('path').attr('id', d => 'line-' + d.word)
				.attr('class', 'line')
				.attr('stroke', d => (isHighlight(d) ? '#ff07f1' : '#c968ff'))
				// .attr('stroke', '#c968ff')
				.style('mix-blend-mode', d => {
					if (existsHighlight) return (isHighlight(d) ? 'hard-light' : 'difference')
					return 'hard-light'
				})
				.style('opacity', d => {
					if (!existsHighlight) return 0.6
					return isHighlight(d) ? 0.8 : 0.15
				})
				.attr('d', d => line(d.percentages))
			.filter(d => isHighlight(d))
				.raise()

	}, [month, selected, data, search])

	const wordlistWidth = 220
	const plotWidth = 800
	const chartWidth = wordlistWidth + plotWidth

	return (
		<div
			className='chart'
			style={{ height: chartHeight, width: chartWidth }}
		>
			<div style={{ height: headerHeight, userSelect: 'none', display: 'flex' }}>
				<div style={{ flexGrow: 1, paddingTop: 20, paddingLeft: 40, }}>
					<div style={{
						fontSize: 30,
						fontWeight: 'bold',
						lineHeight: 1,
					}}>100 words most used by <span style={{ color: 'var(--light-blue)' }}>Biden</span> and <span style={{ color: 'var(--light-red)' }}>Trump</span> on Twitter</div>
					<div style={{ fontSize: 20, }}>
						combined, <span style={{ fontWeight: isMonth() ? 'bold' : 'normal' }}>{isMonth() ? `in ${dateFormat(getCurDateString(), 'mmmm yyyy')}` : <>in the year leading up to the election <span style={{ opacity: 0.5 }}>(click on chart to filter by month)</span></>}</span>
					</div>
				</div>
				<div style={{ flex: `0 0 ${wordlistWidth}px`, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', }}>
					<div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, }}>
						<div
							className={(selected.length === 0 && !isMonth() && search === '') ? '' : 'opacity-on-hover'}
							style={{
								fontSize: (selected.length === 0 && !isMonth() && search === '') ? 15 : 19,
								opacity: (selected.length === 0 && !isMonth() && search === '') ? 0.6 : undefined,
								textAlign: 'center',
								cursor: (selected.length === 0 && !isMonth() && search === '') ? 'default' : 'pointer',
							}}
							onClick={() => {
								selected.length > 0 && setSelected([])
								isMonth() && setMonth(null)
								search && setSearch('')
							}}
						>
							{(selected.length === 0 && !isMonth() && search === '') ? 'click a word or month' : 'clear selections'}
						</div>
					</div>
					<div style={{ userSelect: 'none', marginLeft: 15, marginRight: 19, padding: '0 10px', display: 'flex', justifyContent: 'space-between', alignSelf: 'stretch' }}>
						<span>{selected.length === 0 ? 'word' : 'rank'}</span>
						<span>{isMonth() ? '# in month' : 'count'}</span>
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', width: chartWidth, height: plotHeight, }}>
				{/* plot */}
				<div ref={ref} id='word-lines' style={{ flexGrow: 1 }} />

				{/* clickable words */}
				<div style={{ flex: `0 0 ${wordlistWidth}px`, display: 'flex', flexDirection: 'column' }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<input
							type='text'
							style={{
								backgroundColor: 'transparent',
								outline: 'none',
								border: 'none',
								padding: '7px 20px',
								color: 'white',
								flexShrink: 1,
								width: 0,
								flexGrow: 1,
								// marginTop: 10,
							}}
							className='word-input'
							placeholder='search for a word'
							value={search}
							onChange={event => setSearch(event.target.value)}
						/>
						{search.length > 0 &&
							<i
								className='material-icons opacity-on-hover'
								style={{
									marginRight: '0.75em',
									color: 'white',
									cursor: 'pointer',
									userSelect: 'none',
									fontSize: 17,
								}}
								onClick={() => setSearch('')}
							>clear</i>
						}
					</div>
					<div
						id='word-list'
						style={{overflowY: 'scroll', overflowX: 'hidden' }}
					/>
				</div>
			</div>
		</div>
	)
}

export default WordLines