import './Tweet.css'
import dateFormat from 'dateformat'
import { getTweetNumText } from './functions'

const Tweet = ({
	width=500,
	person='trump',
	name,
	handle,
	text='This is a sample tweet',
	likes='10000',
	retweets='4000',
	date=(new Date()),
	href,
	hrefFromId,
	color='white',
	customColor,
	profile=true,
	fontSize=19,
	footerFontSize=19,
	style={},
	transitionDuration='0s',
}) => {

	if (hrefFromId !== undefined) {
		switch (person.toLowerCase()) {
			case 'trump':
				href = `https://twitter.com/realDonaldTrump/status/${hrefFromId}`
				break
			case 'biden':
				href = `https://twitter.com/JoeBiden/status/${hrefFromId}`
				break
		}
	}

	const borderColor = customColor || `var(--tweet-${color})`
	if (!name && !handle) {
		switch (person.toLowerCase()) {
			case 'trump':
				name = 'Donald J. Trump'
				handle = 'realDonaldTrump'
				break
			case 'biden':
				name = 'Joe Biden'
				handle = 'JoeBiden'
				break
		}
	}
	return (
		<div
			className='tweet'
			style={{ width: width - 32, borderColor, transitionDuration, ...style }}
		>
			<div className='tweet-header'>
				{profile &&
					<div className='tweet-profile' style={{ borderColor, marginRight: 10, height: fontSize >= 19 ? 44 : (fontSize + 2) * 2, width: fontSize >= 19 ? 44 : (fontSize + 2)* 2, transitionDuration }} />
				}
				<div style={{ flexGrow: 1 }}>
					<div style={{ fontWeight: 'bold', fontSize: fontSize >= 19 ? 16 : fontSize - 1, transitionDuration }}>{name}</div>
					{handle &&
						<div style={{ fontWeight: 'lighter', fontSize: fontSize >= 19 ? 16 : fontSize - 1, transitionDuration }}>@{handle}</div>
					}
				</div>
				{href &&
					<a href={href} target='_blank' title='Go to tweet'><i className='material-icons opacity-on-hover' style={{ fontSize: fontSize + 5, margin: 3, alignSelf: 'flex-start', color: 'white', userSelect: 'none' }}>link</i></a>
				}
			</div>

			<div className='tweet-text' style={{ fontSize, transitionDuration, }}>
				{text}
			</div>

			<div className='tweet-time'>
				<div style={{ flexGrow: 1, fontSize: footerFontSize - 5, transitionDuration, }}>
					{dateFormat(date, 'h:MM TT · mmm d, yyyy')}
				</div>
				{/* <i className='tweet-icon material-icons-outlined'>info</i> */}
			</div>

			<div
				className='tweet-footer'
			>
				<div style={{ marginRight: 28, display: 'flex', alignItems: 'center', transitionDuration }}>
					<i className='tweet-icon material-icons' style={{ fontSize: footerFontSize, transitionDuration, }}>favorite_border</i>
					<div style={{ marginLeft: 8, fontSize: footerFontSize - 5, transitionDuration, }}>{getTweetNumText(likes)}</div>
				</div>
				<div style={{ marginRight: 28, display: 'flex', alignItems: 'center', transitionDuration }}>
					<i className='tweet-icon material-icons-outlined' style={{ fontSize: footerFontSize, transitionDuration, }}>mode_comment</i>
					<div style={{ marginLeft: 8, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: footerFontSize - 5, transitionDuration, }}>{getTweetNumText(retweets)}</div>
				</div>
			</div>
		</div>
	)
}

export default Tweet
