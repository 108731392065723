import * as d3 from 'd3'
import d3cloud from 'd3-cloud'
import { useEffect, useRef, useState } from 'react'
import Tweet from '../Tweet/Tweet'
import React from 'react'
import { getTweetNumText } from '../Tweet/functions'

const MakeYourOwn = ({ chartHeight }) => {

	const [isTrump, setIsTrump] = useState(true) //false is Biden
	const [isComplete, setComplete] = useState(false)
	const [tweet, setTweet] = useState([])

	const [trumpData, setTrumpData] = useState(null)
	const [bidenData, setBidenData] = useState(null)

	const [bidenTweets, setBidenTweets] = useState([])
	const [trumpTweets, setTrumpTweets] = useState([])

	const wordcloudref = useRef(null)

	const trump = {
		isTrump: true,
		isBiden: false,
		name: 'Trump',
		full_name: 'Donald J. Trump',
		handle: 'realDonaldTrump',
		color: 'red',
		similarity: 72,
		bigrams: trumpData,
		mostSimilarTweets: [],
		likes: 0,
		retweets: 0,
	}
	const biden = {
		isTrump: false,
		isBiden: true,
		name: 'Biden',
		full_name: 'Joe Biden',
		handle: 'JoeBiden',
		color: 'blue',
		similarity: 28,
		bigrams: bidenData,
		mostSimilarTweets: [],
		likes: 0,
		retweets: 0,
	}
	const current = isTrump ? trump : biden
	const other = !isTrump ? trump : biden
	const tweetText = tweet.join(' ')

	// get data
	useEffect(() => {
		const getData = (file, setData) => {
			d3.csv(
				`/data/${file}`,
				d => {
					const next_words = d.next_words.split(' ')
					const counts = d.counts.split(' ')
					return {
						word: d.word,
						next_words: next_words.map((w, i) => ({
							word: w,
							count: parseInt(counts[i]),
						})),
					}
				}
			).then(array => {
				const data = {}
				array.map(d => data[d.word] = d.next_words)
				setData(data)
			})
		}

		getData('bigrams_trump.csv', setTrumpData)
		getData('bigrams_biden.csv', setBidenData)

		const getTweets = (file, setTweets) => {
			d3.csv(
				`/data/${file}`,
				d => ({
					...d,
					words: d.words.split(' ')
				})
			).then(setTweets)
		}

		getTweets('trump_tweets.csv', setTrumpTweets)
		getTweets('biden_tweets.csv', setBidenTweets)
	}, [])

	// remove last word on backspace
	useEffect(() => {
		const onBackspace = event => {
			if (event.key !== 'Backspace' || tweet.length === 0) return
			setTweet(tweet.slice(0, tweet.length - 1))
			if (isComplete) {
				setComplete(false)
			}
		}
		window.addEventListener('keydown', onBackspace)

		// reset scroll
		const listTrump = d3.select('#sim-tweet-list-Trump').node()
		if (listTrump) listTrump.scrollTo(0, 0)
		const listBiden = d3.select('#sim-tweet-list-Biden').node()
		if (listBiden) listBiden.scrollTo(0, 0)

		return () => {
			window.removeEventListener('keydown', onBackspace)
		}
	}, [isComplete, tweet])

	const sum = array => array.reduce((a, b) => a + b, 0)

	// if (isComplete) {

		// get most similar tweets
		const stopwords = ['this', 'will', 'that', 'have', 'they', 'with', 'their', 'from', 'were', 'should', 'than', 'your', 'just', 'what', 'doing', 'when', 'many', 'been', 'there', 'those', 'these', 'going', 'said']
		const gowords = ['our', 'you', 'aoc', 'now', 'new', 'big', 'day', 'joe', 'job', 'win', 'i', 'my', 'me', 'im', 'god']
		const isntStopWord = w => (gowords.includes(w) || (w.length > 3 && !stopwords.includes(w)))
		const filteredTweet = tweet.filter(isntStopWord)

		// jaccard similarity
		const sim = d => {
			let docwords = d.words.filter(isntStopWord)
			let intersection = [...docwords].filter(w => filteredTweet.includes(w)).length
			let union = new Set([...docwords, ...filteredTweet]).size
			if (filteredTweet.length === 0) {
				intersection = [...d.words].filter(w => tweet.includes(w)).length
				union = new Set([...d.words, ...tweet]).size
			}
			return intersection / union
		}
		const compare = (a, b) => {
			const aSim = sim(a)
			const bSim = sim(b)
			if (aSim > bSim) return -1
			if (aSim < bSim) return 1
			return 0
		}

		// add similarity % to most similar tweets
		trump.mostSimilarTweets = trumpTweets.sort(compare).slice(0, 30)
			.map(d => ({
				...d,
				similarity: sim(d),
			}))
		biden.mostSimilarTweets = bidenTweets.sort(compare).slice(0, 30)
			.map(d => ({
				...d,
				similarity: sim(d),
			}))


	const decimalToPercent = dec => Math.round(dec * 100)

	// similarity metrics
	const getSimilarity = tweets => {
		const decimal = Math.max(...tweets.map(d => d.similarity))
		return decimalToPercent(decimal)
	}
	trump.similarity = getSimilarity(trump.mostSimilarTweets)
	biden.similarity = getSimilarity(biden.mostSimilarTweets)

	// get estimated number of likes and retweets
	if (isComplete) {
		trump.likes = 0
		trump.retweets = 0
		biden.likes = 0
		biden.retweets = 0

		trump.mostSimilarTweets.forEach(tweet => {
			trump.likes += sim(tweet) * tweet.likes
			trump.retweets += sim(tweet) * tweet.retweets
		})
		const tSumSim = sum(trump.mostSimilarTweets.map(sim))
		trump.likes *= (trump.similarity * 0.01) / tSumSim
		trump.retweets *= (trump.similarity * 0.01) / tSumSim

		biden.mostSimilarTweets.forEach(tweet => {
			biden.likes += sim(tweet) * tweet.likes
			biden.retweets += sim(tweet) * tweet.retweets
		})
		const bSumSim = sum(biden.mostSimilarTweets.map(sim))
		biden.likes *= (biden.similarity * 0.01) / bSumSim
		biden.retweets *= (biden.similarity * 0.01) / bSumSim
	} else {
		trump.likes = 0
		trump.retweets = 0
		biden.likes = 0
		biden.retweets = 0
	}

	useEffect(() => {

		if (!current.bigrams) return

		const lastword = tweet.length > 0 ? tweet[tweet.length - 1] : '<start>'

		if (lastword === '<end>') return

		let next_words = []
		if (lastword in current.bigrams) {
			next_words = current.bigrams[lastword]
				.filter(d => {
					if (d.word === '<end>') return true
					return (tweetText.length + d.word.length <= 140)
				})
				.slice(0, 50)
			if (!next_words.map(d => d.word).includes('<end>')) {
				const count = next_words.length > 0 ? Math.min(next_words.map(d => d.count)) : 1
				next_words.push({ word: '<end>', count, })
			}
		} else {
			next_words.push({ word: '<end>', count: 1, })
		}

		const wordcloudheight = wordcloudref.current.getBoundingClientRect().height
		const wordcloudwidth = wordcloudref.current.getBoundingClientRect().width
		var margin = { top: 20, right: 20, bottom: 0, left: 20 },
			width = wordcloudwidth - margin.left - margin.right,
			height = wordcloudheight - margin.top - margin.bottom

		d3.select(wordcloudref.current).selectAll('*').remove()

		const size = d3.scaleLog().domain(d3.extent(next_words, w => w.count)).range([10, height * 0.2])
		const words = next_words.map(d => ({ word: d.word, size: size(d.count) }))

		const svg = d3.select(wordcloudref.current)
			.append('div')
			.style('flex-grow', 1)
			.style('height', height + 'px')
			.style('overflow', 'hidden')
			.append("svg")
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
			.append("g")
			.attr("transform", "translate(" + margin.left + "," + margin.top + ")")

		const layout = d3cloud()
			.size([width, height])
			.words(words.map(d => ({ text: d.word, size: d.size, ...d })))
			.padding(6)        //space between words
			.rotate(0)
			.fontSize(d => d.size)      // font size of words
			// .font('Impact')     // TODO: change font?
			// .spiral('rectangular')
			.on("end", draw)
		layout.start()

		function draw(words) {
			svg.append("g")
				.attr("transform", "translate(" + layout.size()[0] / 2 + "," + layout.size()[1] / 2 + ")")
				.selectAll("text")
				.data(words)
				.enter().append("text")
				.style("font-size", d => d.size)
				.style("fill", d => d.word !== '<end>' ? `var(--light-${current.color})` : (current.isTrump ? '#ffb2b2' : '#a9e3ff'))
				.style('cursor', 'pointer')
				.style('user-select', 'none')
				.attr("text-anchor", "middle")
				.style('transition-duration', '0.1s')
				// .style("font-family", "Impact")
				.attr("transform", d => "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")")
				.text(d => d.text)
				.on('click', (_, d) => {
					if (d.text === '<end>') {
						setComplete(true)
					} else {
						setTweet([...tweet, d.word])
					}
				})
				.on('mousemove', function () {
					d3.select(this).style('opacity', 0.7)
				})
				.on('mouseout', function () {
					d3.select(this).style('opacity', 1)
				})
		}

	}, [tweet, isTrump, bidenData, trumpData, isComplete])

	let title = `choose your ${tweet.length === 0 ? 'first' : 'next'} word`
	let subtitle = `from words likely to ${tweet.length === 0 ? 'start off' : 'come next in'} a ${current.name} tweet`

	const chartWidth = 1200
	const leftWidth = isComplete ? 430 : 400
	const rightWidth = chartWidth - leftWidth
	const headerHeight = 100

	const footerHeight = 42
	const isFooter = isComplete

	const transitionDuration = '1s'

	return (
		<div
			className='chart'
			style={{
				height: chartHeight,
				width: chartWidth,
				padding: '0 20px 20px 20px',
				// display: 'flex',
				// flexDirection: 'column',
			}}
		>
			{/* header */}
			<div style={{
				display: 'flex',
				userSelect: 'none',
				alignItems: 'flex-start',
				marginLeft: 10,
				paddingTop: 5,
				paddingBottom: 10,
				height: headerHeight - 15,
			}}>
				<div
					style={{
						flexGrow: 1,
						marginTop: 20,
					}}
				>
					<div style={{
						fontSize: 30,
						fontWeight: 'bold',
						lineHeight: 1,
					}}>{(() => {
						if (isComplete) {
							return <>You made your own <span style={{ color: `var(--light-${current.color})` }}>{current.name}</span> tweet!</>
						}
						return <>Make your own <span style={{ color: `var(--light-${current.color})` }}>{current.name}</span> tweet</>
					})()}</div>
					{!isComplete &&
						<div style={{
							opacity: 0.6,
						}}>based on real tweets by <a className='link-no-styling' href={`https://twitter.com/${current.handle}/`} target='_blank' >@{current.handle}</a> in the year leading up to the election</div>
					}
				</div>
				{!isComplete &&
					<div
						className='opacity-on-hover'
						style={{
							marginRight: 20,
							marginTop: 20,
							color: `var(--light-${other.color})`,
							cursor: 'pointer',
							fontSize: 25,
						}}
						onClick={() => setIsTrump(!isTrump)}
					>switch to {other.name}</div>
				}
				{isComplete &&
					<div style={{ display: 'flex', alignSelf: 'flex-end' }}>
						{[current, other].map(person => (
							<div
								style={{
									fontSize: 32,
									userSelect: 'none',
									width: (rightWidth - 20) / 2,
									textAlign: 'center',
									display: 'flex',
									alignItems: 'flex-end',
									justifyContent: 'center',
								}}
							>
								<div style={{ fontSize: 71, marginRight: 25, lineHeight: 1, color: `var(--light-${person.color})`, fontWeight: 'bold' }}>
									{person.similarity}<span style={{ fontSize: 53 }}>%</span>
								</div>
								<div style={{ fontSize: 23, lineHeight: 1.4, marginBottom: 7, opacity: 0.9 }}>
									similar to real<br />{person.name} tweets
								</div>
							</div>
						))}
					</div>
				}
			</div>
			<div style={{
				// flexGrow: 1,
				display: 'flex',
				height: chartHeight - headerHeight - (isFooter ? footerHeight : 0),
			}}>
				{/* left: tweet and stats */}
				<div style={{ width: leftWidth, display: 'flex', flexDirection: 'column', transitionDuration, }}>
					<Tweet
						width={leftWidth - 20}
						text={
							<div style={{ minHeight: 80, padding: '20px 0' }}>
								{tweetText}
							</div>
						}
						likes={current.likes}
						retweets={current.retweets}
						person={current.name}
						transitionDuration={transitionDuration}
						fontSize={isComplete ? 22 : 19}
						footerFontSize={isComplete ? 22 : 19}
					/>
					{isComplete &&
						<div style={{ textAlign: 'center', userSelect: 'none', marginTop: 7 }}>Based on similar tweets, this tweet would get an estimated</div>
					}
					{!isComplete &&
						<div className='opacity-transition' style={{ padding: isComplete ? 15 : 30, opacity: tweet.length > 0 ? 1 : 0, }}>
							{[current, other].map(person => (
								<div key={person.name} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', paddingBottom: person.name === current.name ? 20 : 0, userSelect: 'none', alignItems: 'center' }}>
									<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
										<div style={{ fontSize: 71, marginRight: 25, lineHeight: 1, color: `var(--light-${person.color})`, fontWeight: 'bold' }}>
											{person.similarity}<span style={{ fontSize: 53 }}>%</span>
										</div>
										<div style={{ fontSize: 23, lineHeight: 1.4, marginBottom: 10, opacity: 0.9 }}>
											similar to real<br />{person.name} tweets
										</div>
									</div>
								</div>
							))}
						</div>
					}
					{isComplete &&
						<div style={{ padding: isComplete ? 15 : 30, opacity: tweet.length > 0 ? 1 : 0, }}>
							{[current, other].map(person => (
								<div key={person.name} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', paddingBottom: person.name === current.name ? 20 : 0, userSelect: 'none', alignItems: 'center' }}>
									<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
										<div
											className='tweet-style'
											style={{ fontSize: 71, marginRight: 25, lineHeight: 1, color: `var(--light-${person.color})`, display: 'flex', textAlign: 'right', justifyContent: 'center', alignSelf: 'stretch', marginBottom: 6, }}
										>
											<span style={{ fontSize: 25, display: 'inline-flex', alignItems: 'center', justifyContent: 'flex-start', margin: '0 15px' }}>
												<i className='material-icons' style={{ fontSize: 25, marginTop: 2, marginRight: 12, }}>favorite_border</i>
												{getTweetNumText(person.likes)}
											</span>
											<span style={{ fontSize: 25, display: 'inline-flex', alignItems: 'center', justifyContent: 'flex-start', margin: '0 15px' }}>
												<i className='material-icons-outlined' style={{ fontSize: 25, marginTop: 2, marginRight: 12, }}>mode_comment</i>
												{getTweetNumText(person.retweets)}
											</span>
										</div>
										<div style={{ fontSize: 23, lineHeight: 1.4, opacity: 0.9, }}>
											if tweeted from {person.name}'s account
										</div>
									</div>
								</div>
							))}
						</div>
					}
					<div
						className='opacity-on-hover opacity-transition'
						style={{
							cursor: 'pointer',
							fontSize: 25,
							fontWeight: 'bold',
							flexGrow: 1,
							textAlign: 'center',
							userSelect: 'none',
							display: (!isComplete && tweet.length > 0) ? 'inherit' : 'none',
							opacity: (!isComplete && tweet.length > 0) ? 1 : '0 !important',
							alignSelf: 'center',
						}}
						onClick={() => setTweet([])}
					>start over</div>
				</div>
				{/* right: word cloud */}
				<div style={{
					width: rightWidth,
					display: !isComplete ? 'flex' : 'none',
					flexDirection: 'column',
					userSelect: 'none',
					transitionDuration,
				}}>
					<div style={{ textAlign: 'center', paddingTop: 7, paddingBottom: 5, fontSize: 30, fontWeight: 'bold', lineHeight: 1 }}>{title}</div>
					<div style={{ textAlign: 'center', lineHeight: 1, opacity: 0.85 }}>{subtitle}</div>
					<div id='myo-wordcloud' style={{ flexGrow: 1 }} ref={wordcloudref}></div>
					<div className='opacity-transition' style={{
						display: 'flex',
						justifyContent: 'space-between',
						padding: '0 100px',
						marginBottom: 0,
						marginTop: 10,
						textAlign: 'center',
						height: 60,
						alignItems: 'center',
						opacity: tweet.length > 0 ? 1 : 0,
					}}>
						<div
							className='opacity-on-hover'
							style={{ cursor: 'pointer' }}
							onClick={() => setTweet(tweet.slice(0, tweet.length - 1))}
						>
							<div style={{ fontWeight: 'bold', fontSize: 25, lineHeight: 1, }}>undo</div>
							<div style={{ opacity: 0.85 }}>press backspace</div>
						</div>
						<div
							className='opacity-on-hover'
							style={{ cursor: 'pointer' }}
							onClick={() => setComplete(true)}
						>
							<div style={{ fontWeight: 'bold', fontSize: 25, lineHeight: 1, marginBottom: 5 }}>&lt;end&gt;</div>
							<div style={{ opacity: 0.85, lineHeight: 1.1, fontSize: 13 }}>you can always go back<br /> and continue editing</div>
						</div>
					</div>
				</div>
				{/* right: similar tweets */}
				<div style={{
					display: isComplete ? 'flex' : 'none',
					userSelect: 'none',
					transitionDuration,
					paddingLeft: 20,
					width: rightWidth - 20,
				}}>
					{[current, other].map(person => (
						<div key={person.name} style={{ width: rightWidth / 2, display: 'flex', flexDirection: 'column' }}>
							<div style={{
								textAlign: 'center',
								paddingTop: 5,
								paddingBottom: 10,
								fontSize: 25,
								fontWeight: 'bold',
								lineHeight: 1,
								flexShrink: 0
							}}>similar {person.name} tweets</div>
							<div id={`sim-tweet-list-${person.name}`} style={{ overflowY: 'scroll', overflowX: 'hidden', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
								{person.mostSimilarTweets.map(t => (
									<React.Fragment key={t['']}>
										<div style={{ alignSelf: 'center', margin: '0 45px', lineHeight: 1.2 }}>{decimalToPercent(t.similarity)}% similar</div>
										<Tweet
											width={(rightWidth / 2) - 75}
											person={person.name}
											color={person.color}
											text={t.text}
											fontSize={13}
											date={new Date(t.date)}
											likes={t.likes}
											retweets={t.retweets}
											style={{ marginTop: 0, marginBottom: 20, }}
											hrefFromId={t.id}
										/>
									</React.Fragment>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
			{/* footer */}
			{isFooter &&
				<div style={{
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'flex-end',
					lineHeight: 1,
					height: footerHeight,
				}}>
					<div
						className='opacity-on-hover'
						style={{
							cursor: 'pointer',
							fontSize: 20,
							flexGrow: 1,
							paddingBottom: 5,
							marginRight: 20,
							textAlign: 'center',
							userSelect: 'none',
						}}
						onClick={() => setComplete(false)}
					>continue editing your tweet</div>
					<div
						className='opacity-on-hover'
						style={{
							fontSize: 30,
							color: `var(--light-${current.color})`,
							userSelect: 'none',
							cursor: 'pointer',
							width: (rightWidth - 20)/2,
							textAlign: 'center',
						}}
						onClick={() => {
							setComplete(false)
							setTweet([])
						}}
					>
						make another {current.name} tweet
					</div>
					<div
						className='opacity-on-hover'
						style={{
							fontSize: 32,
							color: `var(--light-${other.color})`,
							userSelect: 'none',
							cursor: 'pointer',
							fontWeight: 'bold',
							width: (rightWidth - 20) / 2,
							textAlign: 'center',
						}}
						onClick={() => {
							setComplete(false)
							setTweet([])
							setIsTrump(!isTrump)
						}}
					>
						make a {other.name} tweet next
					</div>
				</div>
			}
		</div>
	)
}

export default MakeYourOwn
